import { AppBar, Toolbar, Box, Typography, Link } from "@mui/material";

const Header = () => {
    return (
        <AppBar position="fixed" color="default">
            <Toolbar>
                <Box display="flex" flexGrow={1}>
                    <Link href="/" sx={{ color: "black", textDecoration: "none" }}>
                        <Typography variant="h6">tacit</Typography>
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
