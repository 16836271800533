import { Box, Stack, Typography, Link, useTheme, useMediaQuery } from '@mui/material';
import tacitSquare from '../../images/tacit_square.png';

const FooterLink = ({ href, children } : { href: string, children: React.ReactNode }) => {
    return (
        <Typography variant="body1">
            <Link sx={{ color: "black", fontWeight: "bold", textDecoration: "none" }} href={href}>
                {children}
            </Link>
        </Typography>
    );
}

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    return (
        <Box sx={{ 
            display: "flex",
            height: isMobile ? "auto" : "30vh",
            px: isMobile ? "10vw" : "6vw",
            alignItems: "center",
            backgroundColor: "#f5f5f5"
        }}>
            <Stack my={10} spacing={10} direction={isMobile ? "column" : "row"}>
            {!isMobile && <img src={tacitSquare} alt="tacit_square" height={100} />}
            <Stack spacing={5}>
                    <Typography sx={{ textDecoration: "underline" }} variant="subtitle1">About</Typography>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms">Terms of Service</FooterLink>
                </Stack>
                <Stack spacing={5}>
                    <Typography sx={{ textDecoration: "underline" }} variant="subtitle1">Need Help?</Typography>
                    <FooterLink href="/contact">Contact Us</FooterLink>
                </Stack>
            </Stack>
        </Box>
    );
};

export default Footer;
