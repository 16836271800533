import { Button, Link } from "@mui/material";
import { AppLinkContainer } from ".";
import googlePlayBadge from "../../../images/google_play_badge.png";

const AndroidAppLink = () => {
    const appUrl = "https://play.google.com/store/apps/details?id=com.leodeng.tacit";

    return (
        <AppLinkContainer version="closed beta">
            <Link href={appUrl} target="_blank" rel="noopener noreferrer" underline="none">
                <Button>
                    <img src={googlePlayBadge} alt="Get it on Google Play" height={50} />
                </Button>
            </Link>
        </AppLinkContainer>
    );
};

export default AndroidAppLink;