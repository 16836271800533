import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Container } from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/footer";
import Home from "./components/home";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/user/*", element: <Home />},
  { path: "privacy", element: <PrivacyPolicy /> },
]);

const App = () => {

  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <RouterProvider router={router} />
      </Container>
      <Footer />
    </>
  );
}

export default App;
