import { Box, Typography } from "@mui/material";

export { default as AndroidAppLink } from "./android";
export { default as IosAppLink } from "./ios";

export const AppLinkContainer = ({ version, children }: { version: string, children: React.ReactNode }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} textAlign={"center"}>
            {children}
            <Typography variant="caption">
                ({version})
            </Typography>
        </Box>
    );
}