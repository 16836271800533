import { Button, Link } from "@mui/material";
import { AppLinkContainer } from ".";
import appleStoreBadge from "../../../images/apple_store_badge.svg";

const IosAppLink = () => {
    const appUrl = "https://testflight.apple.com/join/S8tmsag0";

    return (
        <AppLinkContainer version="private beta">
            <Link href={appUrl} target="_blank" rel="noopener noreferrer" underline="none">
                <Button>
                    <img src={appleStoreBadge} alt="Download on the App Store" height={50} />
                </Button>
            </Link>
        </AppLinkContainer>
    );
};

export default IosAppLink;