import { Typography, Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import { AndroidAppLink, IosAppLink } from "./links";
import tacitSquare from "../../images/tacit_square.png";

const Home = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box my={isMobile ? 12 : "2vh"} display={"flex"} flexDirection={isMobile ? "column" : "row"} height={"100vh"} maxWidth={"100vw"} >
            <Box display={"flex"} flexDirection={"column"} flex={1} justifyContent={"center"} alignItems={isMobile ? "center" : "flex-start"} >
                <Box px={2}>
                    <Typography variant="h4" py={2}>Link up with friends effortlessly via silent signals</Typography>
                    <Typography variant="body1">
                        Tacit skips the initial text/call, directly connecting friends who have the time and interest to enjoy each other's company.
                    </Typography>
                </Box>
                <Stack spacing={2} direction={isMobile ? "column" : "row"} py={2}>
                    <IosAppLink />
                    <AndroidAppLink />
                </Stack>
            </Box>
            <Box p={2} display={"flex"} flexDirection={"column"} flex={1} alignItems={"center"} justifyContent={"center"}>
                <Box maxWidth={300}>
                    <img src={tacitSquare} alt="tacit_square" style={{ objectFit: "contain", width: "100%" }}/>
                </Box>
            </Box>
        </Box>
    )
}

export default Home;